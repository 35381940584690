import React from "react"
import SocialLogos from "./socialLogos.js"
import "../../styles/socials.css"
import CarouselWidget from "./Carousel.js"
// import Heading from "../heading.js"

function Socials () {
    return (
        <section className = "socials">
            {/* <Heading title = "In Social Media" size = "h1" class = "head"/> */}
            <div className="social-heading">
                <h2 style= {{fontWeight: '400'}}>@liquidrocketry</h2>
                <SocialLogos className= "social-logo"/>
            </div>
            <hr />
            <div className="social-elements">
                <CarouselWidget />
            </div>
            
        </section>
    )
    
}

export default Socials