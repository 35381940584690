import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import TestStand from "../components/TestStand"
import Socials from "../components/Social-Media/Socials"
import About from "../components/About"
import Leadership from "../components/Leadership"
import Alumni from "../components/Alumni"
import Sponsors from "../components/Sponsors"
import Contact from "../components/Contact"
import Navbar from "../components/Navbar/Navbar"
import styled from "styled-components"

const Wrapper = styled.div`
    display: grid;
    grid-auto-rows: minmax(300px, auto);
    grid-gap: 4em;
    color: #F5F5F7;
    font-family: 'Roboto';
`
const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Navbar />
    <Hero />
    
    <Wrapper>
        <About />
        {/* <Socials /> */}
        <TestStand />
        <Leadership />
	    <Alumni />
        <Sponsors />
        <Contact />
    </Wrapper>
  </Layout>
)

export default IndexPage
