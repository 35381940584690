import React, {  useState, useEffect } from 'react';
import { CarouselProvider, Slider, Slide, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import styled from 'styled-components'

const CarouselContainer = styled.div`
    position: relative;
    width: 100%;
    background-size: contain;
`

const access = 'IGQVJWdXdPakJDd3N1cVNNVDluNUN1R1ZArUTc2dHVxR0tHVWRETXNmVmJjMThqS3k3NURKdXZAuUEZAiWll5dFo2T1BkQzlhalhHRWN6X2J0b3ZAOWnh5b1AxaFBaU1pMV0wzTzE2X25KMElSX2xhejc5dwZDZ'
const url = `https://graph.instagram.com/me/media?fields=caption,media_url,permalink,media_type&access_token=${access}`

const isBrowser = typeof window !== `undefined`

function getWindowDimensions() {
    if(isBrowser) {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    else {
        const width = 720
        const height = 720
        return {
            width,
            height
        };
    }
  }
  
    function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );
  
    useEffect(() => {
      function handleResize() {
        if( isBrowser )
            setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return windowDimensions;
  }

const CarouselWidget = () => {
    const [dataList, setDataList] = useState([])

    async function fetchData() {
        const response = await fetch(url)
        const data = await response.json()
        setDataList( data.data )
    }

    useEffect( () => {
        fetchData()

    }, []) 

    var setCount = 6;
    const { width } = useWindowDimensions();

    if (width <= 760)
        setCount = 3
    
    return (
        <CarouselContainer>
            <CarouselProvider
                naturalSlideWidth={125}
                naturalSlideHeight={125}
                totalSlides={dataList.length}
                step = { 2 }
                isPlaying = {true}
                dragEnabled = {true}
                dragStep = { 2 }
                hasMasterSpinner
                visibleSlides = { setCount }
                interval = {1500}
                touchEnabled = {true}
            >
            {/* <CarouselElements> */}
            {/* <ButtonBack><FontAwesomeIcon icon = { faAngleLeft } size = "1x" /></ButtonBack> */}

            <Slider>
            {
                dataList &&
                dataList.map( (post, idx) => (
                    <Slide index={idx}>
                        <Image 
                            src = { post.media_url } 
                            alt = "insta post" />
                    </Slide>
                ))
            }
            </Slider>
            {/* <ButtonNext><FontAwesomeIcon icon = { faAngleRight } size = "1x" /></ButtonNext> */}
            {/* </CarouselElements> */}
            </CarouselProvider>   
        </ CarouselContainer >    
    )
}

export default CarouselWidget