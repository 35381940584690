import React from "react"
import "../styles/test-stand-model.css"

const TestStandModel = () => {
  return (
    <div className = "test-stand-model-container">
            <div className="sketchfab-embed-wrapper">
                <iframe 
                    className = "model-iframe"
                    width="940" 
                    height="680"
                    title="A 3D model" 
                    src="https://sketchfab.com/models/b929f09ef3094b1eb7286c338519436c/embed?autospin=0.2&amp;autostart=0&amp;preload=1&amp;ui_controls=1&amp;ui_infos=1&amp;ui_inspector=1&amp;ui_stop=1&amp;ui_watermark=1&amp;ui_watermark_link=1" 
                    frameBorder="0" 
                    allow="autoplay; fullscreen;" 
                    mozallowfullscreen="true" 
                    webkitallowfullscreen="true">

                </iframe>
            </div>
    </div>
  )
}

export default TestStandModel
