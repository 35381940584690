import React from "react"
import "../styles/sponsors.css"
import Heading from "./heading.js"
import NCState from "../images/logos/ncstate.svg"
import GP from "../images/logos/gp.png"
import ANSYS from "../images/logos/ANSYS.svg"
import ATL from "../images/logos/atlassian.svg"
import BLUEFORCE from "../images/logos/blueforce.svg"
import WAVETHERM from "../images/logos/wavetherm.svg"
import SIEMENS from "../images/logos/siemens.svg"
import VALWORX from "../images/logos/valworx.png"
import HERMEUS from "../images/logos/hermeus.png"
import ONLOGIC from "../images/logos/OnLogic.png"
import PARTSBOX from "../images/logos/PartsBox.png"
import BOLTDEPOT from "../images/logos/bolt-depot.png"
import COLLIER from "../images/logos/Collier.png"
import ASME from "../images/logos/ASME.png"

const Sponsors = () => (
    <section className= "sponsors">
        <span id = "sponsors"></span>
        <div className="sponsor-wrapper" >
        <Heading title = "OUR PARTNERS" size = "h1" />
            <br />
            <div className="company-grid">
                <div className="sponsor1">
                    <a href="https://www.ncsu.edu/" target= "_blank" rel="noopener noreferrer">
                        <img
                        src={NCState}
                        alt="NC State link"
                        />
                    </a>
                </div>
                <div className="sponsor2">
                    <a href="https://blueforcetech.com/" target= "_blank" rel="noopener noreferrer">
                            <img
                            src={BLUEFORCE}
                            alt="Blueforce link"
                            />
                        </a>
                </div>
                <div className="sponsor3">
                    <a href="https://georgianpartners.com/" target= "_blank" rel="noopener noreferrer">
                        <img
                        src={GP}
                        alt="Georgian Partners link"
                        style = {{
                            height: `70%`,
                            width: `70%`
                        }}
                        />
                    </a>
                </div>
                
                <div className="sponsor4">
                    <a href="https://www.ansys.com/" target= "_blank" rel="noopener noreferrer">
                        <img
                        src={ANSYS}
                        alt="ANSYS link"
                        style = {{
                            height: `70%`,
                            width: `70%`
                        }}
                        />
                    </a>
                </div>
                <div className="sponsor5">
                    <a href="https://www.atlassian.com/" target= "_blank" rel="noopener noreferrer">
                                <img
                                src={ATL}
                                alt="Atlassian link"
                                style = {{
                                    height: `80%`,
                                    width: `80%`
                                }}
                                />
                    </a>
                </div>
                <div className="sponsor6">
                    <a href="https://shop.wavetherm.com/" target= "_blank" rel="noopener noreferrer">
                                <img
                                src={WAVETHERM}
                                alt="Wavetherm link"
                                />
                    </a>
                </div>
                <div className="sponsor7">
                    <a href="https://www.siemens.com/global/en.html" target= "_blank" rel="noopener noreferrer">
                                <img
                                src={SIEMENS}
                                alt="Siemens link"
                                style = {{
                                    height: `80%`,
                                    width: `80%`
                                }}
                                />
                    </a>
                </div>
                <div className="sponsor8">
                    <a href="https://www.valworx.com" target= "_blank" rel="noopener noreferrer">
                                <img
                                src={VALWORX}
                                alt="Valworx Link"
                                style = {{
                                    height: `80%`,
                                    width: `80%`
                                }}
                                />
                    </a>
                </div>
                <div className="sponsor9">
                    <a href="https://www.hermeus.com" target= "_blank" rel="noopener noreferrer">
                                <img
                                src={HERMEUS}
                                alt="Hermeus Link"
                                style = {{
                                    height: `80%`,
                                    width: `80%`
                                }}
                                />
                    </a>
                </div>
                <div className="sponsor10">
                	<a href="https://partsbox.com/" target= "_blank" rel="noopener noreferrer">
                		<img
                		src={PARTSBOX}
                		alt="PartsBox Link"
                		style = {{
                			height: `80%`,
                            width: `80%`
                        }}
                        />
                    </a>
                </div>
                <div className="sponsor11">
                	<a href="https://www.onlogic.com/" target= "_blank" rel="noopener noreferrer">
                				<img
                				src={ONLOGIC}
                				alt="OnLogic Link"
                				style = {{
                					height: `80%`,
                					width: `80%`
                					}}
                				/>
                	</a> 
                </div>
                <div className="sponsor12">
                	<a href="https://boltdepot.com/" target= "_blank" rel="noopener noreferrer">
                				<img
                				src={BOLTDEPOT}
                				alt="Bolt Depot Link"
                				style = {{
                					height: `80%`,
                					width: `80%`
                					}}
                				/>
                	</a> 
            </div>
            <div className="sponsor13">
                	<a href="https://collieraerospace.com/" target= "_blank" rel="noopener noreferrer">
                				<img
                				src={COLLIER}
                				alt="Collier Aerospace Link"
                				style = {{
                					height: `80%`,
                					width: `80%`
                					}}
                				/>
                	</a> 
            </div>
            <div className="sponsor14">
                	<a href="https://sections.asme.org/professional-section-detail?recordId=recmhWzuDQLIftJdQ" target= "_blank" rel="noopener noreferrer">
                				<img
                				src={ASME}
                				alt="NC ASME Link"
                				style = {{
                					height: `80%`,
                					width: `80%`
                					}}
                				/>
                	</a> 
            </div>
        </div>
    </div>
    </section>
)

export default Sponsors
