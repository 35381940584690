import React from "react"
import Heading from "./heading.js"
import { useStaticQuery, graphql } from "gatsby"
import "../styles/about.css"
import BackgroundImage from "gatsby-background-image"
import PitchDeck from "../docs/Pitch_Deck_Fall_2023.pdf"

const About = () => {
    const data = useStaticQuery(graphql` 
    query {
      indexImage: file(relativePath: { eq: "about-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    return (
        <section className = "about">
            <span id = "about"></span>
            <BackgroundImage
                className="about-hero"
                fluid = { data.indexImage.childImageSharp.fluid }
            >
                <div className="overlay-about">
                    <div className="content-box">
                        <Heading title = "ABOUT US" size = "h1" class = "head"/>
                        <br/> <br/>
                        <p>
                        Liquid Rocketry Lab (LRL) is a 501(c)(3) non-profit company comprised of 150+ NCSU students. As one of North Carolina's premiere liquid propulsion and launch vehicle research teams, we're racing to build the first collegiate liquid-powered rocket to reach the Karman line: the edge of space. Additionally, we've designed and built a large mobile test stand, paving the way for a sustainable propulsion program at NC State.
                        </p>
                        <p>
                        Our four sub teams span the majors across campus from every branch of Engineering at NC State. LRL is poised for growth with a network of investors and mentors. Think of us as the extra nudge to propel NC back on top of the aerospace industry.
                        </p>
                        <p>
                        We believe humanity’s future will be in space exploration, and we would like to contribute to that. Our project gives members valuable firsthand experience, and we're always looking for passionate students who are willing to learn.
                        </p>
                        <div className = "pitch-deck" >
                            <a href= {PitchDeck}
                                rel = "noopener noreferrer"
                                target= "_blank" >Check out our Pitch Deck!
                            </a>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        
    </section>
    )
}

export default About