import React from "react"
import "../styles/test-stand.css"
import TestStandModel from "./test-stand-model.js"
import Heading from "./heading.js"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const TestStand = () => {

    const data = useStaticQuery(graphql`
    query {
      indexImage: file(relativePath: { eq: "Test_Stand_Group_Picture.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
    return (
        <section className = "test-stand-wrapper">
        
        <BackgroundImage
        className="test-stand-bg"
        fluid = { data.indexImage.childImageSharp.fluid }
        >
            <span id="test-stand"></span>
            <div className="overlay-test-stand">
            </div>
            
        </BackgroundImage>
        <div className="ts-content-box">
                    <span>OUR PILOT PROJECT</span>
                    <p>
                        <br />
                        We are one of the first amateur teams racing to launch a liquid-propelled rocket to the Karman line: 100km above sea level.  
                    </p>
        </div>
        <div className = "test-stand-content">
            <div className = "content-title">
                <Heading title = "TEST STAND" size = "h1"/>
                <TestStandModel />
                {/* <div className="modelo-1"> 
                    <div className="modelo-2"> 
                        <iframe src="https://app.modelo.io/embedded/h4aIm2y8qF?viewport=true&autoplay=true&autoRotate=true&hideTools=false" 
                            className="modelo-iframe"
                            title="Test-stand-model"
                            mozallowfullscreen = "true" webkitallowfullscreen = "true" allowfullscreen = "true" >
                        </iframe> 
                    </div> 
                </div>  */}
                <p>
                    The LRL Engine Test Stand is a test fixture that allows us to perform full-duration and full-thrust static fires of rocket engines that we develop. This system is fully automated and allows us to safely test and characterize our engines in a consistent environment with the capability of large amounts of data collection. Our test stand is currently the highest capacity mobile engine test stand in development.
                </p>
            </div>
            
        </div>
    </section>
    )
}

export default TestStand