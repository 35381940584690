import React from "react"
import Heading from "./heading.js"
import "../styles/alumni.css"
import John from "../images/leadership/john.png"
import Alex from "../images/leadership/alex.png"
import Abe from "../images/leadership/abe.jpg"
import Mark from "../images/leadership/mark.png"
import Will from "../images/leadership/will.png"
import Nazar from "../images/leadership/nazar.jpg"
import Dario from "../images/leadership/dario.png"
import Kavin from "../images/leadership/kavin.jpg"
import Niall from "../images/leadership/niall.jpg"
import Hassan from "../images/leadership/hassan.png"
import Josh from "../images/leadership/josh.jpg"
import Joey from "../images/leadership/joey_bream.png"
import Morgan from "../images/leadership/morgan.jpg"
import Sagnik from "../images/leadership/sagnik.jpg"
import Cade from "../images/leadership/cade.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faGlobe } from "@fortawesome/free-solid-svg-icons"
import {
  faInstagram,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons"

const Alumni = () => (
  <section className="alumni-wrapper">
    <span id="alumni"></span>
    <Heading title="ALUMNI" size="h1" />
    <div className="alumni-grid">
      <div className="box">
        <figure>
          <img src={Josh} alt="Josh Mithcell Structures Alumnus" />
          <div className="box-title">
            <h3>Josh Mitchell</h3>
            <h6>Former Structures Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/jmitche11/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:jmitche11@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
              <a
                href="https://www.instagram.com/josh_mitche11/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Dario} alt="Dario Muller Avionics Alumnus" />
          <div className="box-title">
            <h3>Dario Muller</h3>
            <h6>Former Avionics Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a href="mailto:dario@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Hassan} alt="Hassan Polani Technical Advisor" />
          <div className="box-title">
            <h3>Hassan Polani</h3>
            <h6>Former Propulsion Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a href="mailto:hassan@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
              <a
                href="https://www.linkedin.com/in/hassan-polani-615173161/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={John} alt="John Slugg Structures Alumnus" />
          <div className="box-title">
            <h3>John Slugg</h3>
            <h6>Former Structures Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/john-slugg-5a1b4ba5/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:John@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
              <a
                href="https://www.johnslugg.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGlobe} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Abe} alt="Abe Weinstein CEO" />
          <div className="box-title">
            <h3>Abe Weinstein</h3>
            <h6>Former CEO</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/abe-weinstein-98a99b178/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:abe@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Niall} alt="Niall Mullane Lead of Programming" />
          <div className="box-title">
            <h3>Niall Mullane</h3>
            <h6>Former Programming Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
            <a
                href="https://niallmullane.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGlobe} size="2x" />
              </a>
              <a
                href="https://www.linkedin.com/in/niallmullane/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:niall@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Joey} alt="Joey Bream Lead of Programming" />
          <div className="box-title">
            <h3>Joey Bream</h3>
            <h6>Former Programming Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/joeybream/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:jbream@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Morgan} alt="Morgan Willis Lead of Propulsion" />
          <div className="box-title">
            <h3>Morgan Willis</h3>
            <h6>Former Propulsion Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/morgan-willis/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:mlwillis26@gmail.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img
            src={Mark}
            alt="Mark Funderburk Chief Safety officer and Testing lead"
          />
          <div className="box-title">
            <h3>Mark Funderburk</h3>
            <h6>Former Chief Safety Officer</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a href="mailto:mark@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img
            src={Sagnik}
            alt="Sagnik Nayak Former Buisness and Mission Control Lead"
          />
          <div className="box-title">
            <h3>Sagnik Nayak</h3>
            <h6>Former Mission Control & Buisness Lead</h6>
          </div>
          <figcaption>
          <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/sagnik10/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Cade} alt="Cade Cuddy Programming Lead" />
          <div className="box-title">
            <h3>Cade Cuddy</h3>
            <h6>Former Programming Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a href="mailto:cjcuddy@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
              <a
                href="https://www.linkedin.com/in/cadecuddy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
    </div>
    <br />
  </section>
)

export default Alumni 
