import React from "react"
import "../styles/heading.css"

function Heading (props) {
    const size = props.size;
    return (
        <div className="heading-wrapper">
            {size === "h1"? <h1>{props.title}</h1> : <h3>{props.title}</h3>}
        </div>
        
    )
}

export default Heading